<template>
  <v-carousel height="100%" id="carousel" hide-delimiters>
    <v-carousel-item
      v-for="(item, i) in items"
      :src="item.src"
      :key="i"
      lazy="True"
    ></v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          src: "Gallery/Haupthaus.jpg"
        },
        {
          src: "Gallery/IMG_1613.jpg"
        },
        {
          src: "Gallery/IMG_3140.jpg"
        },
        {
          src: "Gallery/IMG_3188.jpg"
        },
        {
          src: "Gallery/IMG_5253.jpg"
        },
        {
          src: "Gallery/IMG_5259.jpg"
        },
        {
          src: "Gallery/IMG_5287.jpg"
        },
        {
          src: "Gallery/IMG_5323.jpg"
        },
        {
          src: "Gallery/IMG_5336.jpg"
        },
        {
          src: "Gallery/IMG_5340.jpg"
        },
        {
          src: "Gallery/IMG_5374.jpg"
        },
        {
          src: "Gallery/IMG_5383.jpg"
        },
        {
          src: "Gallery/IMG_5412.jpg"
        },
        {
          src: "Gallery/IMG_5423.jpg"
        },
        {
          src: "Gallery/IMG_5435.jpg"
        },
        {
          src: "Gallery/IMG_5489.jpg"
        },
        {
          src: "Gallery/IMG_5513.jpg"
        },
        {
          src: "Gallery/IMG_5561.jpg"
        },
        {
          src: "Gallery/IMG_5590.jpg"
        },
        {
          src: "Gallery/IMG_5613.jpg"
        },
        {
          src: "Gallery/IMG_5641.jpg"
        },
        {
          src: "Gallery/IMG_5649.jpg"
        },
        {
          src: "Gallery/IMG_5681.jpg"
        },
        {
          src: "Gallery/IMG_5692.jpg"
        },
        {
          src: "Gallery/IMG_5694.jpg"
        },
        {
          src: "Gallery/IMG_5717.jpg"
        },
        {
          src: "Gallery/IMG_5733.jpg"
        },
        {
          src: "Gallery/IMG_6387.jpg"
        },
        {
          src: "Gallery/IMG_6433.jpg"
        },
        {
          src: "Gallery/IMG_6443.jpg"
        },
        {
          src: "Gallery/IMG_6448.jpg"
        },
        {
          src: "Gallery/IMG_6484.jpg"
        },
        {
          src: "Gallery/IMG_8080.jpg"
        },
        {
          src: "Gallery/IMG_8116_2.jpg"
        },
        {
          src: "Gallery/IMG_8159.jpg"
        },
        {
          src: "Gallery/IMG_8184.jpg"
        },
        {
          src: "Gallery/IMG_8250.jpg"
        },
        {
          src: "Gallery/IMG_8268.jpg"
        },
        {
          src: "Gallery/IMG_8297.jpg"
        },
        {
          src: "Gallery/IMG_8301.jpg"
        },
        {
          src: "Gallery/IMG_8330.jpg"
        },
        {
          src: "Gallery/IMG_8416.jpg"
        }
      ]
    };
  }
};
</script>

<style>
.v-image__image {
  max-width: 100%;
  max-height: 100%;
  background-size: contain;
}
#carousel {
  position: absolute;
}

.v-window-item {
  height: 100%;
}

#carousel .icon {
  color: black !important;
}
</style>
