<template>
  <v-app>
    <navigation></navigation>
    <v-content>
      <router-view />
    </v-content>
    <v-footer :fixed="fixed" app>
      <span>&copy; 2022</span>
    </v-footer>
  </v-app>
</template>

<script>
import Navigation from "./components/Navigation.vue";
export default {
  data() {
    return {
      fixed: true
    };
  },
  name: "App",
  components: {
    Navigation
  }
};
</script>

<style>
html {
  height: 100vh;
}

body {
  min-height: 100vh;
}

body::before {
  background-image: url("/Gallery/cornfield-3452080_1920.jpg");
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background-size: cover;
}
</style>
