import Vue from "vue";
import Router from "vue-router";
import Gallery from "./components/Gallery";
import Home from "./components/Home";
import Stallordnung from "./components/Stallordnung";
import Impressum from "./components/Impressum";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home
    },
    {
      path: "/Gallerie",
      name: "Gallerie",
      component: Gallery
    },
    {
      path: "/Stallordnung",
      name: "Stallordnung",
      component: Stallordnung
    },
    {
      path: "/Impressum",
      name: "Impressum",
      component: Impressum
    }
  ]
});
