<template>
  <div>
    <v-navigation-drawer
      persistent
      :mini-variant="miniVariant"
      :clipped="clipped"
      v-model="drawer"
      enable-resize-watcher
      fixed
      app
    >
      <v-list>
        <v-list-tile
          value="true"
          v-for="(item, i) in items"
          :key="i"
          :to="item.link"
        >
          <v-list-tile-content>
            <v-list-tile-title v-text="item.title"></v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>
    <v-toolbar app :clipped-left="clipped">
      <v-toolbar-side-icon @click.stop="drawer = !drawer"></v-toolbar-side-icon>
      <img
        src="@/assets/logo.jpg"
        alt="Diepeschrath Logo"
        height="100%"
        style="padding: 5px"
      />
      <v-toolbar-title
        class="hidden-xs-only"
        id="toolbar-title"
        v-text="title"
      ></v-toolbar-title>
      <v-spacer></v-spacer>
      <a
        href="https://de-de.facebook.com/LandgutDiepeschrath/"
        style="height: 50%"
      >
        <img
          src="@/assets/FLOGO-144.svg"
          alt="Diepeschrath Logo"
          height="100%"
        />
      </a>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "navigation",
      miniVariant: false,
      fixed: false,
      right: true,
      clipped: false,
      drawer: false,
      rightDrawer: false,
      title: "Reitanlage Diepeschrath",
      items: [
        {
          title: "Home",
          link: "/"
        },
        {
          title: "Gallerie",
          link: "/Gallerie"
        },
        {
          title: "Stallordnung",
          link: "/Stallordnung"
        },
        {
          title: "Impressum",
          link: "/Impressum"
        }
      ]
    };
  },
  methods: {
    say: () => {}
  }
  /*   computed: {
    fixed() {
      return this.$store.state.fixed;
    },
    clipped() {
      return this.$store.state.clipped;
    },
  }, */
};
</script>

<style scoped>
#toolbar-title {
  font-size: 14px;
}

@media only screen and (min-width: 1400px) {
  #toolbar-title {
    font-size: 24px;
  }
}

@media only screen and (min-width: 1024px) {
  #toolbar-title {
    font-size: 20px;
  }
}
</style>
